import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"hover-card",class:{
            'mt-n4 mb-4': hover,
            black: hover && _vm.$vuetify.theme.dark,
            white: hover && !_vm.$vuetify.theme.dark,
        },staticStyle:{"z-index":"2"},attrs:{"elevation":"2","to":_vm.item.route,"exact":""},on:{"click":_vm.registerCta}},[_c(VCardTitle,{staticClass:"text-h4 justify-center",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c(VCardSubtitle,{staticClass:"text-center subtitle-1 mb-n2 mt-1"},[_vm._v(" "+_vm._s(_vm.item.text)+" ")]),_c(VCardActions,{staticClass:"px-4"},[_c(VBtn,{attrs:{"color":"primary","exact":"","to":_vm.item.route,"block":""}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.item.icon))]),_vm._v(" "+_vm._s(_vm.item.buttonText)+" ")],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
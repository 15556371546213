import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"hover-card",class:{
            'mt-n8 mb-8': hover,
            black: hover && _vm.$vuetify.theme.dark,
            white: hover && !_vm.$vuetify.theme.dark,
        },staticStyle:{"z-index":"2"},attrs:{"elevation":"2","href":_vm.url,"exact":""},on:{"click":_vm.registerCta}},[_c(VCardText,[_c(VRow,[_c(VListItem,{staticClass:"grow"},[_c(VListItemAvatar,[_c(VImg,{staticClass:"elevation-6",attrs:{"alt":"Profile Picture","src":_vm.profilePictureUrl}})],1),_c(VListItemContent,{staticClass:"mr-n1"},[_c(VListItemTitle,[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(_vm.name))]),_c('br'),_c('div',{staticClass:"mt-n1"},[_c('a',{staticClass:"text--secondary text-decoration-none caption mt-n1",attrs:{"href":_vm.url,"target":"_blank"}},[_vm._v("@"+_vm._s(_vm.username))])])])],1),_c('a',{attrs:{"href":_vm.url}},[_c(VIcon,{attrs:{"color":"blue"}},[_vm._v(_vm._s(_vm.twitterIcon))])],1)],1)],1),_c(VRow,{staticClass:"px-4"},[_c('p',{staticClass:"text-subtitle-1 text--white"},[_vm._v(_vm._s(_vm.content))])])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }





















































import { Vue, Component, Prop } from "vue-property-decorator";
import { mdiTwitter } from "@mdi/js";
import { addAnalyticsEvent } from "@/plugins/firebase";

@Component
export default class TwitterCard extends Vue {
    @Prop({ required: true, type: String }) name!: string;
    @Prop({ required: true, type: String }) username!: string;
    @Prop({ required: true, type: String }) content!: string;
    @Prop({ required: true, type: String }) url!: string;
    @Prop({ required: true, type: String }) profilePictureUrl!: string;

    twitterIcon: string = mdiTwitter;

    registerCta() {
        addAnalyticsEvent("tweet_testimonial_clicked", {
            username: this.username,
        });
    }
}
